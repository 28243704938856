(function(i, p, w, d, b, t) {

    var sticky_anchor_ad = {
        adConfig: {},

        config: function(settings) {
            obj = {};
            if (settings.large_image && w.innerWidth >= 1170) {
                obj.image = settings.large_image;
                obj.width = 1170;
                obj.height = 90;
                obj.classes = 'hidden-md hidden-sm hidden-xs';
            } else if (settings.medium_image && w.innerWidth >= 970) {
                obj.image = settings.medium_image;
                obj.width = 970;
                obj.height = 90;
                obj.classes = 'hidden-sm hidden-xs';
            } else if (settings.small_image && w.innerWidth >= 728) {
                obj.image = settings.small_image;
                obj.width = 728;
                obj.height = 90;
                obj.classes = 'hidden-xs';
            } else if (settings.mobile_image && w.innerWidth >= 320) {
                obj.image = settings.mobile_image;
                obj.width = 320;
                obj.height = 50;
                obj.classes = 'hidden-lg hidden-md';
            }

            return obj;
        },

        css: function(settings) {
            var styles = '<style>';
            styles += '#x-sticky-container { width:100%; position:fixed; left:0px; bottom:0px; z-index:1039; background-color:rgba(0,0,0,0.25); }';
            styles += '#x-sticky-wrapper { position:relative; margin:0 auto; }';
            if (this.adConfig.width == 320) {
                styles += '#x-sticky-wrapper { width:auto; height:auto; }';
                styles += '@media (min-width: 320px) { #x-sticky-wrapper { width:320px; height:50px; } }';
            } else {
                styles += '#x-sticky-wrapper { width:'+this.adConfig.width+'px; height:'+this.adConfig.height+'px; }';
            }
            styles += '#x-sticky-ad { width:100%; height:100%; max-width:100%; max-height:100%; position:absolute; top:0px; left:0px; }';
            styles += '#x-sticky-close { display:block; margin:0px; padding:0px; width:20px; height:20px; color:#222; position:absolute; z-index:1039; opacity:0.8; top:-20px; right:0px; cursor:pointer; }';
            styles += '#x-sticky-close svg { pointer-events:none; }';
            styles += '.x-sticky-image { margin:0 auto; }';
            styles += '</style>';

            return styles;
        },

        html: function(settings) {
            var html = this.css();
            html += '' +
            '<div id="x-sticky-container">' +
                '<div id="x-sticky-wrapper" class="'+this.adConfig.classes+'">' +
                    '<a id="x-sticky-link" href="'+ settings.click_tag +'" target="_blank" rel="noopener">';
                        html += '<img class="x-sticky-image img-responsive" src="' + this.adConfig.image + '">';
                    html += '</a>';
                    if (settings.tracking_pixel) {
                        html += '<div style="display:none;"><img src="' + this.settings.tracking_pixel + '" height="1" width="1" /></div>';
                    }
                    html+= '<a id="x-sticky-close">' +
                        '<i class="fas tnt-times"></i>' +
                        '<span class="sr-only">Close</span>' +
                    '</a>' +
                '</div>' +
            '</div>';

            return html;
        },

        load: function(obj) {
            this.adConfig = this.config(obj);

            var adId = p.parentNode.parentNode.id;
            var slotExists = false;
            if (w.__tnt && w.__tnt.ads && w.__tnt.ads.slots && w.__tnt.ads.slots[adId]) {
                slotExists = true;
                w.__tnt.ads.slots[adId].sticky = true;
                w.__tnt.ads.slots[adId].viewableImpression = true;
            }

            if (b.classList.contains('app-eedition')) {
                var seg_container = d.getElementById('eedition-segments-container');
                if (d.getElementById('eedition-page-bottom').contains(p)) {
                    seg_container.appendChild(this.css(obj));
                    seg_container.querySelector('#eedition-sticky-container-page').innerHTML = this.html(obj);
                } else {
                    seg_container.appendChild(this.css(obj));
                    seg_container.querySelector('#eedition-sticky-container-text').innerHTML = this.html(obj);
                }
            } else {
                p.parentNode.insertAdjacentHTML('afterend', this.html(obj));
            }

            var loadedEvent = new CustomEvent('tntStickyAnchorLoaded', { detail: {
                element: d.getElementById('x-sticky-container')
            }});
            var closeEvent = new Event('tntStickyAnchorClosed');
            d.dispatchEvent(loadedEvent);

            d.addEventListener('click', function(e) {
                if (e.target.id === 'x-sticky-close') {
                    var container = d.getElementById('x-sticky-container');
                    container.parentNode.removeChild(container);
                    d.dispatchEvent(closeEvent);
                    if (slotExists) {
                        delete w.__tnt.ads.slots[adId];
                        slotExists = false;
                    }
                }
            });
        }
    };

    try {
        if (!i) throw 'Friendly iframe required.';

        t.cmds.forEach(function(item) {
            sticky_anchor_ad.load(item.call());
            return false;
        });
    } catch(e) {
        if (w.console) w.console.warn(e);
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.__TNT_AD || {})
);